




















import Vue from "vue";
import { Watch } from "vue-property-decorator";

import Component from "vue-class-component";
import { nFormatter } from "@/helper/utils";
import Chart from "chart.js/auto";

import Widget from "@/components/common/Widget.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import ChartDataLabels from "chartjs-plugin-datalabels";

import store from "@/store";

const datalabels = {
  labels: {
    start: {
      anchor: "start",
      align: "bottom",
      offset: 4,
      formatter: (v: any) => (v >= 0 ? "" : nFormatter(v, 0)),
    },
    end: {
      anchor: "end",
      align: "top",
      offset: 4,
      formatter: (v: any) => (v <= 0 ? "" : nFormatter(v, 0)),
    },
  },
};

@Component({
  components: { Widget, DashboardItemWrapper },
})
export default class LiquiditaetsplanungChart extends Vue {
  public static id = "LiquiditaetsplanungChart";
  public static dprops = { width: 8, height: 4, minWidth: 4, minHeight: 4 };
  chart: any;
  get noDataFound() {
    return this.chartData.columns.lenght === 0;
  }

  get _id() {
    return LiquiditaetsplanungChart.id;
  }
  get _dprops() {
    return LiquiditaetsplanungChart.dprops;
  }

  get chartData() {
    return store.getters.liquiditaetsplanunTable;
  }

  get chartType() {
    return store.getters.chartType;
  }

  get themeType() {
    return store.getters.themeType;
  }

  @Watch("chartData")
  @Watch("themeType")
  @Watch("chartType")
  initChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    this.renderChart();
  }

  renderChart() {
    const labels: string[] = this.chartData.columns.slice(1).slice(0, -2);
    if (labels.length === 0) return;
    const style = getComputedStyle(document.body);
    const primary = style.getPropertyValue("--primary");
    const primaryLight = style.getPropertyValue("--primary-light");
    const primaryDark = style.getPropertyValue("--primary-dark");

    const ctx = window.document.getElementById(
      "LiquiditaetsplanungChartCanvas"
    )! as any;

    this.chart = new Chart(ctx, {
      type: this.chartType,
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [
          {
            label: this.chartData.items[2].title,
            borderRadius: 4,
            data: this.getRowValues(this.chartData.items[2]),
            borderColor: primary,
            backgroundColor: primary,
          },
          {
            label: this.chartData.items[6].title,
            borderRadius: 4,
            data: this.getRowValues(this.chartData.items[6]),
            borderColor: primaryLight,
            backgroundColor: primaryLight,
          },
          {
            label: this.chartData.items[32].title,
            borderRadius: 4,
            data: this.getRowValues(this.chartData.items[32]),
            borderColor: primaryDark,
            backgroundColor: primaryDark,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: datalabels as any,
          legend: {
            display: true,
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function(value: any, index: any, values: any) {
                const v = labels[index];
                if (v.length > 8) {
                  return labels[index].slice(0, 8) + "...";
                } else {
                  return labels[index];
                }
              },
            },
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
          y: {
            ticks: {
              callback: function(v: any) {
                return nFormatter(v, 0);
              },
            },
            grid: {
              display: true,
              borderDash: [8, 4],
              borderColor: "transparent",
            },
          },
        },
      },
    });
  }

  getRowValues(data: any) {
    const values: number[] = [];

    for (let col = 0; col < this.chartData.columns.length; col++) {
      const label = this.chartData.columns[col];

      if (label !== "title") {
        values.push(data[label]);
      }
    }

    return values.slice(0, -2);
  }
}
