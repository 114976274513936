



















import Vue from "vue";
import Component from "vue-class-component";
import { Liquiditaetsplanung } from "uknow-react-components";
import Widget from "@/components/common/Widget.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import { Watch } from "vue-property-decorator";
import store from "@/store";
import api from "@/api";

@Component({
  components: { Liquiditaetsplanung, Widget, DashboardItemWrapper },
})
export default class LiquiditaetsplanungTable extends Vue {
  public static id = "LiquiditaetsplanungTable";
  public static dprops = { width: 8, height: 6, minWidth: 8, minHeight: 6 };

  loading = false;

  mounted() {
    this.onParamsChange();
  }

  get _id() {
    return LiquiditaetsplanungTable.id;
  }
  get _dprops() {
    return LiquiditaetsplanungTable.dprops;
  }

  get autoHeight() {
    return !window.location.pathname.startsWith("/dashboard");
  }

  get columns() {
    return store.getters.liquiditaetsplanunTable.columns;
  }
  get items() {
    return store.getters.liquiditaetsplanunTable.items;
  }

  get params() {
    return {
      stb: store.getters.selectedBiAgency,
      client: store.getters.selectedClient,
      year: store.getters.planYear,
    };
  }

  @Watch("params")
  onParamsChange() {
    if (this.params.client === null) return;

    this.loading = true;
    store.dispatch("updateLiquiditaetsplanunTable", { columns: [], items: [] });

    const stb: string = this.params.client && this.params.client[0];
    const selectedStb = this.params.stb != null ? this.params.stb : stb;

    const mandant: string = this.params.client[1];
    const year: string = this.params.year;

    api.getLiquiditaetsplanung(selectedStb, mandant, year).then((data) => {
      this.loading = false;
      store.dispatch(
        "updateLiquiditaetsplanunTable",
        data === null ? { columns: [], items: [] } : data
      );
    });
  }
}
