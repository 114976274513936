


































import Vue from "vue";
import Component from "vue-class-component";
import TopBar from "@/components/common/TopBar.vue";
import readXlsxFile from "read-excel-file";
import LiquiditaetsplanungTable from "@/components/DashboardItems/LiquiditaetsplanungTable.vue";
import LiquiditaetsplanungChart from "@/components/DashboardItems/LiquiditaetsplanungChart.vue";
import { Watch } from "vue-property-decorator";
import store from "@/store";
import api from "@/api";

type Item = { [column: string]: string | number };
type ExcelRow = (string | number)[];

@Component({
  components: { LiquiditaetsplanungTable, TopBar, LiquiditaetsplanungChart },
})
export default class Liquiditaetsplanung extends Vue {
  get params() {
    return {
      stb: store.getters.selectedBiAgency,
      client: store.getters.selectedClient,
      year: store.getters.planYear,
    };
  }

  get hasData() {
    return store.getters.liquiditaetsplanunTable.columns.length > 0;
  }

  pushStateHistory(stb: any, mandant: any, year: any, range: any) {
    const selectedStb = this.params.stb != null ? this.params.stb : stb;

    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  @Watch("params")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let stb: string = this.params.client && this.params.client[0];
    let mandant: string = this.params.client[1];
    let year: string = this.params.year;

    this.pushStateHistory(stb, mandant, year, store.getters.rangeStep);
  }

  uploadExcelClick() {
    const input = document.getElementById("excel-input");
    input && input.click();
  }

  handleExcelAdd(event: any) {
    readXlsxFile(event.target.files[0], {}).then((rows) => {
      const items: Item[] = [];

      const columns = rows[2] as ExcelRow;
      columns[0] = "title";

      for (let index = 4; index <= 42; index++) {
        const row = rows[index] as ExcelRow;
        if (row[0] === null) continue;
        const item: Item = {};
        for (let i = 0; i < row.length; i++) {
          const cell = row[i];
          item[columns[i]] = cell;
        }
        items.push(item);
      }

      store.dispatch("updateLiquiditaetsplanunTable", { items, columns });
      const stb: string = this.params.client && this.params.client[0];
      const selectedStb = this.params.stb != null ? this.params.stb : stb;

      const mandant: string = this.params.client[1];
      const year: string = this.params.year;

      api.updateLiquiditaetsplanung(selectedStb, mandant, year, {
        columns,
        items,
      });
    });
  }

  handleExcelDelete() {
    if (
      confirm(
        "Sind Sie sicher, dass Sie die Daten dieser Tabelle löschen möchten?"
      )
    ) {
      store.dispatch("updateLiquiditaetsplanunTable", {
        items: [],
        columns: [],
      });
      const stb: string = this.params.client && this.params.client[0];
      const selectedStb = this.params.stb != null ? this.params.stb : stb;

      const mandant: string = this.params.client[1];
      const year: string = this.params.year[0];
      api.updateLiquiditaetsplanung(selectedStb, mandant, year, {
        columns: [],
        items: [],
      });
    }
  }
}
